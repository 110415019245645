import './app/App';
import '../public-path';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import { getRegion } from './utils/cookie';
import intl from 'react-intl-universal';
import cookie from 'react-cookies';

const content = document.querySelector('#main-content');

// 国际化
const locales = {
  enUS: require('./locales/en-US.json'),
  zhCN: require('./locales/zh-CN.json'),
}
const setLang = () => {
  let lang = cookie.load('locale') || navigator.language || navigator.browserLanguage
  if (undefined === lang || '' === lang) {
    lang = 'zhCN';
  } else {
    if (lang.indexOf('zh') !== -1) {
      lang = 'zhCN';
    } else {
      lang = 'enUS';
    }
  }
  cookie.save('locale', lang);
}
setLang();

function render(props) {
  const { container } = props;
  const region = getRegion();
  let currentLocale = intl.determineLocale({
    urlLocaleKey: "locale",
    cookieLocaleKey: "locale",
  })
  intl.init({ currentLocale, locales }).then(() => {
    ReactDOM.render(<App content={content.innerHTML} />, container ? container.querySelector('.app-wrapper') : document.querySelector('.app-wrapper'));
  })

  // fetch(`/${region}/dataLabel/api/idp`).then((res) => {
  //   // 国际化
    
  // }).catch((err) => {
  //   console.log(err, 'error')
  // });
  
}

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

export async function bootstrap() { }

export async function mount(props) {
  render(props);
}

export async function unmount(props) {
  const { container } = props;
  ReactDOM.unmountComponentAtNode(container ? container.querySelector('#root') : document.querySelector('#root'));
}
