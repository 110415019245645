import React, { useEffect, useRef, useState, useContext} from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../components';
import { Form, Input } from '../../components/Form';
import { Modal } from '../../components/Modal/Modal';
import { Space } from '../../components/Space/Space';
import { useAPI } from '../../providers/ApiProvider';
import { useFixedLocation, useParams } from '../../providers/RoutesProvider';
import { BemWithSpecifiContext } from '../../utils/bem';
import { isDefined } from '../../utils/helpers';
import intl from 'react-intl-universal';
import "./ExportPage.styl";
import PathDropdown from '../../components/PathDropdown';
import { cn } from '../../utils/bem';
import { GlobalContext } from '../../store';
const importClass = cn("upload_page");
// const formats = {
//   json: 'JSON',
//   csv: 'CSV',
// };

const downloadFile = (blob, filename) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

const {Block, Elem} = BemWithSpecifiContext();

const wait = () => new Promise(resolve => setTimeout(resolve, 5000));

export const ExportPage = () => {
  const history = useHistory();
  const location = useFixedLocation();
  const pageParams = useParams();
  const api = useAPI();
  const [path,setPath] = useState('/')

  const [previousExports, setPreviousExports] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [downloadingMessage, setDownloadingMessage] = useState(false);
  const [availableFormats, setAvailableFormats] = useState([]);
  const [currentFormat, setCurrentFormat] = useState('JSON');

  const { labelSouce } = useContext(GlobalContext);
  const [saveloading, setSaveloading] = useState(false);
  const [dataSetName, setDataSetName] = useState();

  /** @type {import('react').RefObject<Form>} */
  const form = useRef();

  const proceedExport = async () => {
    setDownloading(true);

    const message = setTimeout(() => {
      setDownloadingMessage(true);
    }, 1000);

    const params = form.current.assembleFormData({
      asJSON: true,
      full: true,
      booleansAsNumbers: true,
    });

    const response = await api.callApi('exportRaw', {
      params: {
        pk: pageParams.id,
        ...params,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      downloadFile(blob, response.headers.get('filename'));
    } else {
      api.handleError(response);
    }

    setDownloading(false);
    setDownloadingMessage(false);
    clearTimeout(message);
  };

  const proceedIdpExport = async (type) => {
    setDownloading(true);
    setSaveloading(true);

    const message = setTimeout(() => {
      setDownloadingMessage(true);
    }, 1000);

    const params = form.current.assembleFormData({
      asJSON: true,
      full: true,
      booleansAsNumbers: true,
    });
    const args = {
      pk: pageParams.id,
      // IdpExportDirPath: `/`,
      IdpExportDirPath: path || `/`,
      ...params,
    };
    if (type == 'lm') Object.assign(args, { lmExportName: dataSetName || 'datasets'});
    const response = await api.callApi('idpexport', {
      params: {...args},
    });
    if (response?.$meta?.status === 200) {
      // alert('Successfully exported to the workspace directory "/"')
      alert(`${intl.get('Successfully')} ${path || '/'}`)
    } else {
      api.handleError(response);
    }
    setSaveloading(false);
    setDownloading(false);
    setDownloadingMessage(false);
    clearTimeout(message);
  };

  useEffect(() => {
    if (isDefined(pageParams.id)) {
      api.callApi("previousExports", {
        params: {
          pk: pageParams.id,
        },
      }).then(({export_files}) => {
        setPreviousExports(export_files.slice(0, 1));
      });

      api.callApi("exportFormats", {
        params: {
          pk: pageParams.id,
        },
      }).then(formats => {
        setAvailableFormats(formats);
        setCurrentFormat(formats[0]?.name);
      });
    }
  }, [pageParams]);

  // const formatSelect = (
  //   <Label text="Export format" style={{display: "block", width: "mix-content"}} flat>
  //     <Dropdown.Trigger content={(
  //       <Menu size="medium">
  //         {Object.entries(formats).map(([key, value]) => {
  //           return <Menu.Item key={key} onClick={() => setFormat(key)}>{value}</Menu.Item>;
  //         })}
  //       </Menu>
  //     )}>
  //       <Button size="small" style={{textAlign: "left"}}>
  //         <span>{formats[format]}</span>
  //       </Button>
  //     </Dropdown.Trigger>
  //   </Label>
  // );

  // const aggregation = (
  //   <Form.Row columnCount={2}>
  //     {/* TODO: We don't have api for different formats yet, so let's hide the select for now */}
  //     {/* {formatSelect} */}

  //     <RadioGroup label="Aggregation of annotations" size="small" name="aggregator_type" labelProps={{size: "large", flat: true}}>
  //       <RadioGroup.Button value="majority_vote" checked>
  //         Majority vote
  //       </RadioGroup.Button>
  //       <RadioGroup.Button value="no_aggregation">
  //         No aggregation
  //       </RadioGroup.Button>
  //     </RadioGroup>
  //   </Form.Row>
  // );

  // const exportHistory = (
  //   <Label text="Previous exports" size="large" flat>
  //     {previousExports.map(file => {
  //       const basename = file.url.split('/').reverse()[0];
  //       return (
  //         <Button key={file.url} href={file.url} size="medium" download={basename} icon={<FaFileDownload/>}>
  //           {basename}
  //         </Button>
  //       );
  //     })}
  //   </Label>
  // );

  return (
    <Modal
      onHide={() => {
        const path = location.pathname.replace(ExportPage.path, '');
        const search = location.search;
        history.replace(`${path}${search !== '?' ? search : ''}`);
      }}
      title={intl.get('EXPORT_DATA')}
      style={{width: 720}}
      closeOnClickOutside={false}
      allowClose={!downloading}
      // footer="Read more about supported export formats in the Documentation."
      visible
    >
      <Block name="export-page">
        <FormatInfo
          availableFormats={availableFormats}
          selected={currentFormat}
          onClick={format => setCurrentFormat(format.name)}
        />

        <Form ref={form}>
          <Input type="hidden" name="exportType" value={currentFormat}/>

          {/* {aggregation} */}

          {/*<Form.Row columnCount={3} style={{marginTop: 24}}>*/}
          {/*  <Toggle label="Only finished tasks" name="finished" checked/>*/}
          {/*  <Toggle label="Include full task descriptions" name="return_task" checked/>*/}
          {/*  <Toggle label="Include predictions" name="return_predictions" checked/>*/}
          {/*</Form.Row>*/}
        </Form>

        <Elem name="footer">
          <Space style={{width: '100%'}} spread>
          <Elem name="recent">
              
            </Elem>
            <Elem name="actions">
              <Space>
              <div className={importClass.elem("url-form") + " inline"} autoComplete="off">
                {
                  labelSouce == 'labeling' ? <>
                    <Input onChange={(e)=> {
                      setDataSetName(e.target.value);
                    }} placeholder={intl.get('DATASET_INPUT_MSG')}/>
                    <Elem
                      tag={Button}
                      name="finish"
                      look="primary"
                      onClick={()=>{
                        proceedIdpExport('lm');
                      }}
                      waiting={saveloading}
                      
                    >
                      {intl.get('SAVE_DATASET')}
                    </Elem>
                  </> : <>
                  <PathDropdown value={path} setVal={setPath} isDirFilter={true} />
                    <Elem
                      tag={Button}
                      name="finish"
                      look="primary"
                      onClick={proceedIdpExport}
                      waiting={downloading}
                    >
                      {intl.get('EXPORT_EXPORT')}
                    </Elem>
                </>
                }
                </div>
                <Elem
                  tag={Button}
                  name="finish"
                  look="primary"
                  onClick={proceedExport}
                  waiting={downloading}
                >
                  {intl.get('EXPORT_DOWNLOAD')}
                </Elem>
              </Space>
            </Elem>
          </Space>
        </Elem>
      </Block>
    </Modal>
  );
};

const FormatInfo = ({availableFormats, selected, onClick}) => {
  return (
    <Block name="formats">
      <Elem name="info">{intl.get('EXPORT_DATA_FORMATS')}</Elem>
      <Elem name="list">
        {availableFormats.map(format => (
          <Elem
            key={format.name}
            name="item"
            mod={{
              active: !format.disabled,
              selected: format.name === selected,
            }}
            onClick={!format.disabled ? () => onClick(format) : null}
          >
            <Elem name="name">
              {format.title}

              <Space size="small">
                {format.tags?.map?.((tag, index) => (
                  <Elem key={index} name="tag">{tag}</Elem>
                ))}
              </Space>
            </Elem>

            {format.description && <Elem name="description">{format.description}</Elem>}
          </Elem>
        ))}
      </Elem>
      <Elem name="feedback">
        Can't find an export format?
        <br/>
        Please let us know in <a className="no-go" href="https://slack.labelstudio.heartex.com/?source=product-export">Slack</a> or submit an issue to the <a className="no-go" href="https://github.com/heartexlabs/label-studio-converter/issues">Repository</a>
      </Elem>
    </Block>
  );
};

ExportPage.path = "/export";
ExportPage.modal = true;
